import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../layouts/en';
import SEO from '../components/seo';

const Page = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 100px;
  color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 15px;
`;

const Content = styled.article`
  max-width: 600px;
`;

export default function MarkdownTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout location={{ pathname: frontmatter.path }}>
      <Page>
        <SEO title={frontmatter.title} />
        <Content>
          <h1>{frontmatter.title}</h1>
          <h2>Last updated: {frontmatter.date}</h2>
          <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
        </Content>
      </Page>
    </Layout>
  );
}

MarkdownTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
